/**
 * Copyright Amazon.com, Inc. and its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You
 * may not use this file except in compliance with the License. A copy of
 * the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is
 * distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF
 * ANY KIND, either express or implied. See the License for the specific
 * language governing permissions and limitations under the License.
 */
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { LoadScript } from "@react-google-maps/api";
import { Passwordless } from "amazon-cognito-passwordless-auth";
import {
  PasswordlessContextProvider,
  Passwordless as PasswordlessComponent
} from "amazon-cognito-passwordless-auth/react";
import { ToastContextProvider } from "../amazon-cognito-passwordless-auth/react/ToastContextProvider";
import {AuthenticatorsManager } from "../amazon-cognito-passwordless-auth/react/components"
import "amazon-cognito-passwordless-auth/passwordless.css";
import "@cloudscape-design/global-styles/index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Route, useNavigate  } from "react-router-dom";
import ClientRedirectionHandler from './ClientRedirectionHandler';
import Registration from "../amazon-cognito-passwordless-auth/react/registration";
import PersonalizeAvatar from "../amazon-cognito-passwordless-auth/react/PersonalizeAvatar";
import PasswordReset from "../amazon-cognito-passwordless-auth/react/PasswordReset";
import SignOut from "./SignOut";
import '../amazon-cognito-passwordless-auth/window.d.ts'

console.debug("App built at:", import.meta.env.VITE_APP_BUILD_DATE);
console.log(`Version: ${import.meta.env.VITE_VERSION}`);
console.log(`VITE_STORE_TOKEN_LAMBDA_URL is: ${import.meta.env.VITE_STORE_TOKEN_LAMBDA_URL}`);
// console.log(`Version2: ${import.meta.env.VITE_VERSION2}`);
// console.log(`user pool id :, ${import.meta.env.VITE_USERS_COGNITO_POOL_ID}`);
var showRegisterPage=false;
var showForgotPasswordEmailPage=false;
var showPassComponent=false;

export function getEnvironmentValueOf(
  variable: string) {
    var variableValue = import.meta.env[variable];
    return variableValue;
  }

function getQueryParam(param: string){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
}
var mrsClientid, givenRedirectUrl, clientName, clientDetails, lmsSiteId;
mrsClientid = getQueryParam('mrsClientId');
givenRedirectUrl = getQueryParam('redirectUrl');
clientName = getQueryParam('clientName');
lmsSiteId = getQueryParam('clientSiteId');

if((window.location.pathname == '/register' )){
  showRegisterPage=true
  showForgotPasswordEmailPage=false
}else if(window.location.pathname == "/handleUserPasswordChange"){
  showRegisterPage=false
  showForgotPasswordEmailPage=true
}else{
  showRegisterPage=false
  showForgotPasswordEmailPage=false
}

if(showRegisterPage || showForgotPasswordEmailPage){
  showPassComponent=false
}else{
  showPassComponent=true
}

const clientDetailsMap: { [key: string]: { cardLogo: string;} } = {
  AccountConsole: { cardLogo: '/all-assets/logos/accountconsole_logo.svg'},
  StructureCMS: { cardLogo: '/all-assets/logos/original_structurecms.logo.png'},
  Deployer: {cardLogo: '/all-assets/logos/original_deployer.svg'},
  EmailDesigner: {cardLogo: '/all-assets/logos/original_email_designer.png'},
  ValidatePlus: {cardLogo: '/all-assets/logos/original_validateplus.png'},
  RithmCrm: {cardLogo: '/all-assets/logos/original_rithmcrm.png'},
  CopyApproval: {cardLogo: '/all-assets/logos/original_copyapproval.logo.svg'},
  Multiplizer: {cardLogo: '/all-assets/logos/original_multiplizer.logo.svg'},
  IgniteCdn: {cardLogo: '/all-assets/logos/original_ignite_cdn.png'},
  MrSignIn: { cardLogo: '/all-assets/logos/mr-signin.svg'},
  StructureCMSLms: { cardLogo: '/all-assets/logos/rithmuniversity.png'},
  Maestro: { cardLogo: '/all-assets/logos/maestro.png'}
};

if(mrsClientid && givenRedirectUrl) {
  localStorage.setItem('givenRedirectUrl', givenRedirectUrl);
  localStorage.setItem('mrsClientid', mrsClientid);
  if (lmsSiteId){
    localStorage.setItem('lmsSiteId', lmsSiteId);
  }
  if(clientName){
    clientDetails = clientDetailsMap[clientName];
  }
}
// import.meta.env.VITE_ENVIRONMENT_TESTING_NAME
Passwordless.configure({
  cognitoIdpEndpoint: import.meta.env.VITE_COGNITO_IDP_ENDPOINT,
  clientId: import.meta.env.VITE_CLIENT_ID,
  fido2: {
    baseUrl: import.meta.env.VITE_FIDO2_BASE_URL,
    authenticatorSelection: {
      userVerification: "required",
    },
  },
  userPoolId: import.meta.env.VITE_USER_POOL_ID,
  debug: console.debug,
});

const brand={
  backgroundImageUrl:
  "/all-assets/BackgroundImages/mr-signin-background.jpg",
  customerName: (clientDetails ? clientDetails.cardLogo : "MrSign.in"),
  customerLogoUrl:
    "/all-assets/logos/mr-signin.svg",
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <PasswordlessContextProvider enableLocalUserCache={true}>
    <ToastContextProvider>
      <Router>
        <Routes>
          {!showPassComponent &&(<Route path="/" element={<PasswordlessComponent brand={brand}/>} />)}
          {/* <Route path="/register" element={<Registration/>} /> */}
          <Route path="/register" element={<LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} libraries={["places"]}><Registration/></LoadScript>} />
          <Route path="/handleUserPasswordChange" element={<PasswordReset  brand={brand}/>} />
        </Routes>
      </Router>
      {(showPassComponent) && (
        <PasswordlessComponent
          brand={{
            backgroundImageUrl:
              "/all-assets/BackgroundImages/mr-signin-background.jpg",
            customerName: (clientDetails ? clientDetails.cardLogo : "MrSign.in"),
            customerLogoUrl:
              "/all-assets/logos/mr-signin.svg",
          }}
        >
          {/* <React.StrictMode> */}
            <Router>
              <Routes>
                <Route path="/" element={<App />} />
                <Route path="/authenticators" element={<AuthenticatorsManager />} />
                <Route path="/clientRedirectionHandler" element={<ClientRedirectionHandler />} />
                <Route path="/handleUserPasswordChange" element={<PasswordReset  brand={brand}/>} />
                <Route path="/signout" element={<SignOut />} />
                <Route path="/personalizeAvatar" element={<PersonalizeAvatar />} />
              </Routes>
            </Router>
          {/* </React.StrictMode> */}
        </PasswordlessComponent>
      )}
    </ToastContextProvider>
  </PasswordlessContextProvider>
);
